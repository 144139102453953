.chatbot {
    font-family: monospace;
    border-radius: 10px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    background: #f5f8fb;
    text-align: center;
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;
}

.header {
    padding: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
    max-height: 58px;
    font-size: 16px;
    font-weight: bold;
    background-color: #002f50;
}

.messages {
    width: 100%;
    height: 75vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.message-container {
    width: 100%;
}

.bot-message {
    float: left;
    padding: 15px 20px;
    margin: 5px;
    border-radius: 20px 20px 20px 1px;
    background: #00aaa5;
    color: white;
    min-width: 40px;
    text-align: left;
}

.user-message {
    float: right;
    padding: 15px 10px;
    margin: 10px;
    border-radius: 20px 20px 1px 20px;
    background: #cccccc;
    color: black;
}

.input {
    position: relative;
}

.input>form>input[type="text"] {
    font-family: monospace;
    font-size: 16px;
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    box-shadow: none;
    box-sizing: border-box;
    opacity: 1;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
    -webkit-appearance: none;
}

.input>form>input:focus {
    outline: none;
}

.input>form>button {
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: #4a4a4a;
    opacity: 1;
    outline: none;
    padding: 14px 16px 12px 16px;
    position: absolute;
    right: 0;
    top: 0;
}